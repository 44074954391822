import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "@fontsource/josefin-sans";
import { FC } from "react";

const theme = extendTheme({
  fonts: {
    heading: "Josefin Sans, sans-serif",
    body: "Josefin Sans, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
});

export const ThemeProvider: FC = (props) => {
  const { children } = props;
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
