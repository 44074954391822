import {
  chakra,
  Grid,
  Heading,
  HStack,
  Image,
  Link,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ImGithub, ImLinkedin } from "react-icons/im";
import { ColorModeSwitcher } from "./ColorModeSwitcher";

const ChakraLinkedInIcon = chakra(ImLinkedin);
const ChakraGithubIcon = chakra(ImGithub);

export const App = () => {
  const { colorMode } = useColorMode();
  return (
    <Grid minH="100vh" p={3}>
      <ColorModeSwitcher justifySelf="flex-end" />

      <VStack
        spacing={10}
        maxWidth={{
          base: "90vw",
          sm: "80vw",
          md: "70vw",
          lg: "60vw",
          xl: "50vw",
        }}
        mx="auto"
      >
        <motion.div
          animate={{
            boxShadow:
              "0 10px 15px -3px rgba(0 0 0, 0.2), 0 4px 6px -2px rgba(0 0 0, 0.5)",
            scale: [0.5, 1.1, 1],
            rotateZ: [0, 5, 0],
            x: [0, 5, 0],
            y: [100, -5, 0],
            opacity: [0, 1, 1],
          }}
          style={{
            borderRadius: "100%",
            position: "relative",
            width: "300px",
            height: "300px",
            overflow: "hidden",
          }}
          transition={{
            times: [0, 0.5, 0.75],
            type: "keyframes",
          }}
        >
          <Image
            src="Headshot.png"
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            minWidth="320px"
            maxWidth="320px"
          />
        </motion.div>
        <VStack spacing={1}>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 300, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.75 }}
          >
            <Heading>Hi there!</Heading>
          </motion.div>
          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 300, opacity: 0 }}
            transition={{ duration: 0.5, delay: 1.25 }}
          >
            <Text fontSize="xl" textAlign="center">
              I'm Calvin, an avid learner, quality fanatic, and clean code
              obsessed software engineer &mdash; currently working with an
              awesome team at{" "}
              <Link
                textDecoration="underline"
                isExternal
                href="https://www.volocopter.com/"
                rel="noopener noreferrer"
              >
                Volocopter
              </Link>{" "}
              on bringing urban air mobility to life. When I'm not coding,
              you'll probably find me painting, working out, or welding stuff.
            </Text>
          </motion.div>
          <HStack spacing={5} pt={10}>
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: [0, 1.1, 1], opacity: [0, 0.75, 1] }}
              transition={{ times: [0.1, 0.2, 0.3], delay: 1.75 }}
            >
              <Link
                fontSize="3xl"
                href="https://www.linkedin.com/in/calvinbayer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ChakraLinkedInIcon
                  fill={colorMode === "light" ? "linkedin.500" : "linkedin.200"}
                  transition="0.25s"
                  _hover={{
                    fill:
                      colorMode === "light" ? "linkedin.600" : "linkedin.300",
                    boxShadow: "lg",
                    mt: "-5px",
                  }}
                  _active={{
                    fill:
                      colorMode === "light" ? "linkedin.700" : "linkedin.400",
                  }}
                />
              </Link>
            </motion.div>
            <motion.div
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: [0, 1.1, 1], opacity: [0, 0.75, 1] }}
              transition={{ times: [0.1, 0.2, 0.3], delay: 2.05 }}
            >
              <Link
                fontSize="3xl"
                href="https://github.com/calvinbayer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ChakraGithubIcon
                  fill={
                    colorMode === "light" ? "blackAlpha.700" : "whiteAlpha.700"
                  }
                  transition="0.25s"
                  _hover={{
                    fill:
                      colorMode === "light"
                        ? "blackAlpha.800"
                        : "whiteAlpha.800",
                    boxShadow: "lg",
                    mt: "-5px",
                  }}
                  _active={{
                    fill:
                      colorMode === "light"
                        ? "blackAlpha.900"
                        : "whiteAlpha.900",
                  }}
                  borderRadius="full"
                />
              </Link>
            </motion.div>
          </HStack>
        </VStack>
      </VStack>
    </Grid>
  );
};
